<script setup>

import { computed, inject, nextTick, ref } from 'vue';

const props = defineProps({
  modelValue: { type: Number, default: null },
  size: { type: String, default: undefined },
});

const emit = defineEmits(['update:modelValue']);
const isNull = computed(() => props.modelValue === null || Number(props.modelValue) === 0);

const sizeClass = computed(() => {
  if (props.size) {
    return `is-${props.size}`;
  } else {
    return null;
  }
});

const feet = computed({
  get() {
    return isNull.value ? null : Math.floor(props.modelValue / 12);
  },
  set(v) {
    const value = parseInt(v) * 12 + parseInt(inches.value || 0);
    updateValue(value);
  },
});

const inches = computed({
  get() {
    return isNull.value ? null : props.modelValue % 12;
  },
  set(v) {
    const value = parseInt(feet.value || 0) * 12 + parseInt(v);
    updateValue(value);
  },
});

const feetRef = ref(null);
const inchesRef = ref(null);

function updateValue(value) {
  checkHtml5Validity();
  const isEmpty = isNaN(value) || value <= 0;
  emit('update:modelValue', isEmpty ? null : value);
  if (isEmpty) {
    feetRef.value.value = '';
    inchesRef.value.value = '';
  }
}

function onInvalid(_e) {
  checkHtml5Validity();
}

function onBlur(_e) {
  checkHtml5Validity();
}

function checkHtml5Validity() {
  let isValid = true;
  if (props.modelValue !== null && props.modelValue < 1) {
    setValidity('danger', 'Please enter a height greater than 1 inch');
    isValid = false;
  } else {
    setValidity(null, null);
  }
  return isValid;
}

const parentField = inject('$field');

function setValidity(variant, message) {
  nextTick(() => {
    if (parentField) {
      if (!parentField.variant) {
        parentField.newVariant = variant;
      }
      if (!parentField.message) {
        parentField.newMessage = message;
      }
    }
  });
}

defineExpose({ checkHtml5Validity });
</script>

<template lang="pug">
OField
  .control
    input.input(
      ref="feetRef"
      v-model.lazy="feet"
      min="0"
      type="number"
      autocomplete="off"
      :class="sizeClass"
      @invalid="onInvalid"
      @blur="onBlur"
    )
  .control
    span.button.is-static(:class="sizeClass") feet
  .control
    input.input(
      ref="inchesRef"
      v-model.lazy="inches"
      min="0"
      type="number"
      autocomplete="off"
      :class="sizeClass"
      @invalid="onInvalid"
      @blur="onBlur"
    )
  .control
    span.button.is-static(:class="sizeClass") inches
</template>

<style scoped>
.control input {
    width: 8rem;
}

.control .button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
</style>
